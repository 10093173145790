.close {
    border:0;
    outline: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    padding: 0;
    /* height: calc(1.5em + (1.5rem + 1px)); */
    width: calc(1.5em + (1.5rem + 2px));
    text-transform: uppercase;
    
    background-color: transparent !important;
}
.close span{
    padding:0;
    font-size: 1.8em;
}
.close:hover{
    color: #009ef6 !important;
}

.rdt_TableBody {
    min-height: 300px !important;
  }
  
  .alert-xs {
    padding: .5rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
    margin: .2rem !important;
    /* width: 50%; */
  }
  .tooltip-inner {
    max-width: 200px;   
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.Visor{
    border: solid 0.5px #ccc;
    border-radius: 5px ;
  }

  
/* Avatar  */
.kt-avatar {
    position: relative !important;
    display: inline-block !important;
  }
  
  .kt-avatar .kt-avatar__holder {
    width: 160px !important;
    height: 160px !important;
    border-radius: 3px !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  
  .kt-avatar .kt-avatar__upload {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
  }
  
  .kt-avatar .kt-avatar__upload input {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden;
    opacity: 0;
  }
  
  .kt-avatar .kt-avatar__upload i {
    position: absolute;
    color: #009ef7;
    font-size: 0.9rem;
  }
  
  .kt-avatar .kt-avatar__upload:hover {
    transition: all 0.3s;
    background-color: #009ef7;
  }
  
  .kt-avatar .kt-avatar__upload:hover i {
    color: #ffffff;
  }
  
  .kt-avatar .kt-avatar__cancel {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: auto;
    right: -10px;
    bottom: -5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
    display: none;
  }
  
  .kt-avatar .kt-avatar__cancel i {
    transition: all 0.3s;
    color: #74788d;
    font-size: 0.9rem;
  }
  
  .kt-avatar .kt-avatar__cancel:hover i {
    transition: all 0.3s;
    color: #009ef7;
  }
  
  .kt-avatar.kt-avatar--changed .kt-avatar__cancel {
    display: flex;
  }
  
  .kt-avatar.kt-avatar--brand .kt-avatar__upload i {
    color: #009ef7;
  }
  
  .kt-avatar.kt-avatar--brand .kt-avatar__upload:hover {
    background-color: #009ef7;
  }
  
  .kt-avatar.kt-avatar--brand .kt-avatar__upload:hover i {
    color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--light .kt-avatar__upload i {
    color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--light .kt-avatar__upload:hover {
    background-color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--light .kt-avatar__upload:hover i {
    color: #282a3c;
  }
  
  .kt-avatar.kt-avatar--dark .kt-avatar__upload i {
    color: #282a3c;
  }
  
  .kt-avatar.kt-avatar--dark .kt-avatar__upload:hover {
    background-color: #282a3c;
  }
  
  .kt-avatar.kt-avatar--dark .kt-avatar__upload:hover i {
    color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--primary .kt-avatar__upload i {
    color: #5867dd;
  }
  
  .kt-avatar.kt-avatar--primary .kt-avatar__upload:hover {
    background-color: #5867dd;
  }
  
  .kt-avatar.kt-avatar--primary .kt-avatar__upload:hover i {
    color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--success .kt-avatar__upload i {
    color: #1dc9b7;
  }
  
  .kt-avatar.kt-avatar--success .kt-avatar__upload:hover {
    background-color: #1dc9b7;
  }
  
  .kt-avatar.kt-avatar--success .kt-avatar__upload:hover i {
    color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--info .kt-avatar__upload i {
    color: #5578eb;
  }
  
  .kt-avatar.kt-avatar--info .kt-avatar__upload:hover {
    background-color: #5578eb;
  }
  
  .kt-avatar.kt-avatar--info .kt-avatar__upload:hover i {
    color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--warning .kt-avatar__upload i {
    color: #ffb822;
  }
  
  .kt-avatar.kt-avatar--warning .kt-avatar__upload:hover {
    background-color: #ffb822;
  }
  
  .kt-avatar.kt-avatar--warning .kt-avatar__upload:hover i {
    color: #111111;
  }
  
  .kt-avatar.kt-avatar--danger .kt-avatar__upload i {
    color: #fd397a;
  }
  
  .kt-avatar.kt-avatar--danger .kt-avatar__upload:hover {
    background-color: #fd397a;
  }
  
  .kt-avatar.kt-avatar--danger .kt-avatar__upload:hover i {
    color: #ffffff;
  }
  
  .kt-avatar.kt-avatar--circle .kt-avatar__holder {
    border-radius: 50%;
  }
  
  .kt-avatar.kt-avatar--circle .kt-avatar__upload {
    right: 0px;
    top: 5px;
  }
  
  .kt-avatar.kt-avatar--circle .kt-avatar__cancel {
    right: 3px;
    bottom: 10px;
  }
  
  .kt-avatar.kt-avatar--outline .kt-avatar__holder {
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }
  
  .kt-avatar .kt-avatar__save {
    display: flex !important;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: auto;
    bottom: -5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 10%);
    display: none;
  }
  
  .kt-avatar .kt-avatar__save:hover{
    color : #28c76f;
  }
  
  .modal-90w {
    width: 90%;
    max-width: none!important;
  }

  .modal-80w {
    width: 80%;
    max-width: none!important;
  }
  .modal-70w {
      width: 70%;
      max-width: none!important;
  }

  .modal-50w {
    width: 60%;
    max-width: none!important;
  }

  .modal-40w {
    width: 60%;
    max-width: none!important;
  }

  
  input[readonly]
{
  background-color: hsl(0, 0%, 95%) !important;
    color: hsl(0, 0%, 60%) !important;
}

.modal-60w {
  width: 95%;
  max-width: none!important;
}

@media (min-width: 1199.98px) {
  .responsiveMap{
    height: 800px!important;
    width: 100%!important;
    z-index: 2 !important
  }
  .modal-60w {
    width: 60%;
    max-width: none!important;
  }
}
@media (max-width: 1198px) {
  .responsiveMap{
    height: 800px!important;
    width: 100%!important;
    z-index: 2 !important
  }
}

@media (max-width: 991.98px) {
  .responsiveMap{
    height: 800px!important;
    width: 100%!important;
    z-index: 2 !important
  }
  
  .logo-emp {
    width: 50% !important;
  }
}

@media (max-width: 767.98px) { 
  .responsiveMap{
    height: 400px!important;
    width: 100%!important;
    z-index: 2 !important
  } 

  .logo-emp {
    width: 50% !important;
  }
}

@media (max-width: 575.98px) { 
  .responsiveMap{
    height: 400px!important;
    width: 100%!important;
    z-index: 2 !important
  }

  .logo-emp {
    width: 50% !important;
  }
}

#logo-size {
  background-size: 100% !important;
  background-position: center;
}

.text-justify {
  text-align: justify;
}

.blue-color {
  color: #24285B;
}

.text-danger-light {
  /* color: #fd397a */
  color: #f92c6d;
}

.text-success-full {
  stroke: #00ab55;
  fill: #00ab55;
}

.text-danger-full {
  stroke: #f92c6d;
  fill: #f92c6d;
}

.state-empresa {
  position: absolute;
  top: 25px !important; 
}

.hs-50 img {
  max-height: 50%;
}

.pb-content-0 {
  padding: 0px 10px 0px 10px !important;
}

.min-h {
  min-height: 46px !important;
}

.background-dark {
  z-index: '10000000000';
  background-color: rgba(0,0,0,0.7);
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}


.text-danger-fill {
  fill: #fd397a;
}

.text-success-fill {
  fill: #1dc9b7;
}

.w-icon-circle {
  width: 11px
}