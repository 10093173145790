//
// Login 1
// Pages SASS files are compiled into separate css files
//


// Initialization of global variables, mixins and functions
@import "../../../init";

.max-h-70px{
    max-height: 100px !important;
}

#kt_login_signin_form{
    max-width: 468px;
}

.alert.alert-custom.alert-light-warning {
    background-color: #FFF4DE;
    border-color: transparent;
}

.alert.alert-custom {
    display: flex;
    align-items: stretch;
    padding: 1.5rem 2rem;
}
.alert.alert-custom.alert-light-warning .alert-text {
    color: #FFA800;
}

.login.login-1 {
    
    .login-aside {
        .aside-img {
            min-height: 450px;
        }
    }

    // Form modes
    .login-signin,
    .login-signup,
    .login-forgot {
        display: none;
    }

    &.login-signin-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: block;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-signup-on {
        .login-signup {
            display: block;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-forgot-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: block;
        }
    }
}


@media (max-width: 991.98px){
    html, body {
        font-size: 12px !important;
    }
}

@media (min-width: 752px) and (max-width: 991px)   {
    .img-aside {
      display: none;
    }
  }

// Desktop view
@include media-breakpoint-up(lg) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 600px;
        }

        .img-aside{
            width: 100%;
        }

        .login-content {
            width: 100%;
            max-width: 500px;

            .login-form {
                width: 100%;
                max-width: 450px;
            }
        }
    }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
    .img-aside{
        width: 100%;
    }
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 450px;
        }
    }
}

// Tablet mode
@include media-breakpoint-down(md) {
    .login.login-1 {
        .img-aside{
            display: none;
        }
    }
    .login.login-1 {
        .login-content {
            .login-form {
                width: 100%;
                max-width: 400px;
            }
        }
    }
}

// Mobile mode
@include media-breakpoint-down(xs) {
    .login.login-1 {
        .aside-img {
            min-height: 300px !important;
            background-size: 400px;
        }
        .login.login-1 {
            .img-aside{
                display: none;
            }
        }

        .login-content {
            .login-form {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
